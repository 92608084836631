@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");

$headerHeight: 60px;
$loaderBackground: #e5e5e5;

.header {
  width: 100%;
  min-height: $headerHeight;
  background-color: white;
}

.max-height {
  /* 
    Using the header height, we want to 
    calculate the max-height for the full screen
    items.
  */
  height: calc(100vh - #{$headerHeight});
}

.max-height-no-header {
  height: 100vh;
}

.loader {
  background-color: white;
  min-width: 384px;
  min-height: 262px;

  p {
    font-size: 28px;
  }
}

.app {
  font-family: Montserrat;
  background-color: $loaderBackground;
  color: black;
  height: 100vh;
}

.console-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ant-btn-primary {
  background: #3162ae !important;
  border-color: #3162ae !important;
}
