@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wrapper{background-color:#fff;padding:42px;min-height:264px;min-width:384px}
.header{width:100%;min-height:60px;background-color:#fff}.max-height{height:calc(100vh - 60px)}.max-height-no-header{height:100vh}.loader{background-color:#fff;min-width:384px;min-height:262px}.loader p{font-size:28px}.app{font-family:Montserrat;background-color:#e5e5e5;color:#000;height:100vh}.console-page-overlay{position:absolute;top:0;left:0;right:0;bottom:0;z-index:1000}.ant-btn-primary{background:#3162ae !important;border-color:#3162ae !important}
